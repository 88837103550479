.new-seo-page .prices {
    margin-left: auto;
    /*margin-right: auto;*/
    max-width: 467px;
}

.new-seo-page .prices {
    margin-left: auto;
    /*margin-right: auto;*/
    max-width: 467px;
}

.new-seo-page .cms-price-table {
    width: 100%;
    text-align: center;
    margin: auto;
    border: 1px solid #ccc;
    margin-top: 15px;
    float: left;
}

.new-seo-page .cms-price-table th {
    border: 1px solid #2d78bd;
    text-align: center;
    background-color: #009eda;
    color: white;
    border-radius: 0px;
}

.new-seo-page .cms-price-table td {
    border: 1px solid #ccc;
    text-align: center;
}

.new-seo-page .cms-price-table .prices_table_receive {
    background-color: #2d78bd;
}

.new-seo-page .cms-price-table .prices_table_payment_options {
    background-color: #18a9e0;
}

@media screen and (min-width: 640px) {
    .pricing-table .package {
        margin-bottom: 40px;
    }

    .pricing-table .package:last-child {
        margin-bottom: 0;
    }
}