.component-header {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
}

.prices .component-header {
    margin-bottom: 15px;
}


@media (max-width: 400px) {
    .component-preloader {
        min-height: 500px;
    }
}

@media (min-width: 400px) {
    .component-preloader {
        min-height: 200px;
    }
}

@keyframes loading {
    0% {
        width: 50pt;
        height: 50pt;
        margin-top: 0;
    }

    25% {
        height: 4pt;
        margin-top: 23pt;
    }

    50% {
        width: 4pt;
    }

    75% {
        width: 50pt;
    }

    100% {
        width: 50pt;
        height: 50pt;
        margin-top: 0;
    }
}

.component-preloader {
    min-height: 500px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.component-header {
    font-size: 20px;
    text-align: center;
    margin-bottom: 10px;
    padding-left: 25px;
    color: black;
    font-weight: bold;
}

.component-loader {
    width: 8vmax;
    height: 8vmax;
    border-right: 4px solid #2f9fd3;
    border-radius: 100%;
    animation: spinRight 800ms linear infinite;
}

.component-loader::before,
.component-loader::after {
    content: '';
    width: 6vmax;
    height: 6vmax;
    display: block;
    position: absolute;
    top: calc(50% - 3vmax);
    left: calc(50% - 3vmax);
    border-left: 3px solid #2f9fd3;
    border-radius: 100%;
    animation: spinLeft 800ms linear infinite;
}

.component-loader::after {
    width: 4vmax;
    height: 4vmax;
    top: calc(50% - 2vmax);
    left: calc(50% - 2vmax);
    border: 0;
    border-right: 2px solid #ffffff;
    animation: none;
}

[v-cloak] > * {
    display: none !important;
}

[v-cloak]::before {
    display: none !important;
}

[v-cloak] {
    display: none !important;
}


@keyframes spinLeft {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(720deg);
    }
}

@keyframes spinRight {
    from {
        transform: rotate(360deg);
    }

    to {
        transform: rotate(0deg);
    }
}
