.new-amount {
    padding: 0px !important;
}

.new-amount-mini .currencyRightField,
.new-amount-small .currencyRightField,
.prices .currencyRightField,
.new-amount .currencyRightField {
    margin-top: -1px;
    cursor: pointer;
}

.new-amount .row .col label,
.new-amount-mini .row .col label,
.new-amount-small .row .col label {
    text-align: left;
}

.new-amount .row, .new-amount-mini .row, .new-amount-small .row {
    padding: 0px;
    margin: 0px;
}

.new-amount .row .col, .new-amount-mini .row .col, .new-amount-small .row .col {
    padding: 0;
}

.new-amount .fca-approved,
.new-amount-mini .fca-approved,
.new-amount-small .fca-approved {
    margin-bottom: 0;
    margin-top: 19px;
}

.new-amount .transfer-summary,
.new-amount-mini .transfer-summary,
.new-amount-small .transfer-summary {
    padding: 20px;
    border-radius: 10px;
    margin-top: 18px;
    margin-bottom: 1px;
}

.new-amount .moneyInputField,
.new-amount-mini .moneyInputField,
.new-amount-small .moneyInputField {
    background-color: #fff;
    border-radius: 10px;
    border: 1px solid #ccc;
    height: 55px;
}

.new-amount-left,
.new-amount-mini-left,
.new-amount-small-left {
    padding: 25px !important;
}

.new-amount-right,
.new-amount-mini-right,
.new-amount-small-right {
    background-color: #18a9e0;
    padding: 26px !important;
    border-radius: 0px 15px 15px 0px;
}

.new-amount-left .row,
.new-amount-right .row,
.new-amount-mini-left .row,
.new-amount-mini-right .row,
.new-amount-small-left .row,
.new-amount-small-right .row {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.currencyRightField {
    display: block;
    border-left: 2px solid #ccc;
    font-weight: bold;
    width: 90px;
    height: 100%;
    float: right;
    line-height: 55px;
    padding-right: 9px
}

/* #region dropdown currency */

.dropdown-tr {
    border-bottom: 1px dotted #D9D9D9;
}

.dropdown-table tbody tr {
    padding: 0 !important;
    text-align: center;
}

.dropdown-table tbody td {
    padding: 0px !important;
    text-align: center;
}


.dropdown {
    display: inline-block;
    position: relative
}

.dropdown-content {
    border: 1px solid #ddd;
    border-radius: 10px;
    display: none;
    min-width: 230px;
    opacity: 1 !important;
    overflow: auto;
    overflow-x: hidden;
    position: absolute;
    z-index: 1;
    max-height: 250px;
}

.dropdown-content a {
    color: #000;
    display: block;
    padding: 12px 16px;
    text-decoration: none
}

.dropdown-content::-webkit-scrollbar {
    width: 12px
}

.dropdown-content::-webkit-scrollbar-track {
    border-radius: 10px;
    box-shadow: inset 0 0 5px grey
}

.dropdown-content::-webkit-scrollbar-thumb {
    background: #959595;
    border-radius: 10px
}

.dropdown-content::-webkit-scrollbar-thumb:hover {
    background: #a4a4a4
}

input[type=text]:focus:not([readonly]) {
    box-shadow: none;
}

input[type="text"].search_input_dropdown {
    background-position: 14px 12px;
    background-repeat: no-repeat;
    border: none;
    border-radius: 0px;
    border-bottom: 1px solid #ddd;
    border-bottom: 2px solid #e5e5e5 !important;
    font-size: 16px;
    margin-bottom: 0 !important;
    padding: 14px 20px 12px 45px;
    width: 90% !important
}

.search_input_dropdown:focus {
    outline: none
}

.dropdown-table tr:hover {
    background-color: #bee1fe
}

.dropdown a:hover {
    background-color: #ddd
}

/* #endregion dropdown currency */

.amount-box-on-landing-page .row .col.l9 {
    width: 100% !important;
    margin-left: 0
}

.amount-box-on-landing-page .continue-button-on-banking-amount {
    margin-top: 10px;
}


.moneyInputField {
    background: white;
    border-radius: 34px;
    /*height:55px;*/
    width: auto;
}

.moneyLeftField {
    float: left;
    display: block;
    margin-left: -17px;
    height: 100%;
    vertical-align: middle;
}

.moneyLeftField input {
    width: 100% !important;
    margin-right: -82px;
    background-color: transparent;
    margin-top: 7px;
}

.currencyRightField {
    display: block;
    border-left: 2px solid #ccc;
    font-weight: bold;
    width: 80px;
    float: right;
    line-height: 55px;
    padding-right: 9px;
    cursor: pointer;
}

.currencyRightField {
    width: 115px !important
}

.displayFlag, .displayCurrency, .arrowImg {
    margin: 3px
}

.displayFlag {
    width: 30px;
    float: left;
    margin-top: 16px
}

.arrowImg {
    width: 15px;
    float: right;
    margin-top: 20px
}

.displayCurrency {
    float: left
}


.continue-button-on-banking-amount {
    margin-left: 0;
    margin-right: 0;
    margin-top: 20px
}


.transfer-summary {
    padding: 30px;
    margin: 0;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
}

.transfer-summary h3 {
    font-size: 24px;
    margin-top: 0;
    line-height: 30px;
    font-weight: 700;
}

.transfer-summary .row:last-child {
    margin-bottom: 0;
}

.transfer-summary .total {
    font-weight: 700;
    font-size: 21px;
    line-height: 21px;
}

.transfer-summary span {
    display: block;
}


.fca-approved {
    background: url('./../../img/icons/fca-safe.png') no-repeat 10px center #4a6aa0;
    border-radius: 10px;
    color: white;
    padding: 1px;
    background-size: 32px 32px;
    margin-bottom: 10px;
    padding-left: 50px;
    padding-right: 20px;
}

.fca-approved a {
    color: #09B2EF;
    font-weight: normal;
    text-decoration: underline;
}


@media screen and (min-width: 640px) 
{
    .new-amount .step-one-col,
    .new-amount-mini .step-one-col,
    .new-amount-small .step-one-col {
        margin-right: 0;
    }

    .transfer-summary {
        padding: 20px;
    }

    .transfer-summary #checkout-agreements-mobile ol.checkout-agreements {
        padding-left: 10px;
        padding-right: 10px;
    }
}

@media only screen and (min-width:600px) 
{
    .moneyLeftField {
        width: 11em
    }
}

@media screen and (max-width: 640px)
{
    .transfer-summary {
        padding: 20px;
    }
}
